import React, { type FC, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent, CheckboxComponent, useSnackbar } from '../../../components';
import { type PayloadInterface, type SavingInvestment } from '../../../interfaces';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { claimFlexibleSavingInvestment, redeemSavingInvestment } from '../../../redux/reducers/savingInvestment';
import { getTotalBalance } from '../../../redux/reducers/cfd';
import { decrementSavingAccountBalance } from '../../../redux/reducers/savingAccounts';
import { type TSavingSection } from '../../../pages/Savings/interface/savingsPageInterfaces';
import { handleError } from '../../../helpers';
import { calcSavingFlexibleEarnings, calculateSavingFlexibleLockPeriod } from '../../../helpers/util';
import { getAccounts } from '../../../redux/reducers/accounts';

import style from './FooterModalRedeem.module.css';

interface InterfaceFooterModalRedeem {
  step: number
  currency: string
  savingAccountId: string
  setStep: (el: number) => void
  setSection?: (el: TSavingSection) => void
  selectedInvestment: SavingInvestment | null
  setOpen: (el: boolean) => void
  isFlexible: boolean | null
}

export const FooterModalRedeem: FC<InterfaceFooterModalRedeem> = ({ step, currency, setStep, selectedInvestment, setSection = () => {}, setOpen, savingAccountId, isFlexible }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleOpen: handleOpenSnackbar } = useSnackbar();
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false);
  const { _id: userId } = useAppSelector(state => state.user);

  const savingsPageStorage = 'Savings-Page/segment-button'

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { target: { checked } } = event;
    setConfirmAgreement(checked);
  }

  const handleFlexibleConfirm = async (): Promise<void> => {
    if (selectedInvestment !== null && userId !== undefined) {
      setIsButtonLoading(true);
      const lockedPeriodInDays = calculateSavingFlexibleLockPeriod(selectedInvestment.createdAt);
      const earnings = calcSavingFlexibleEarnings(selectedInvestment.investedAmount, selectedInvestment.pct, lockedPeriodInDays, 2)

      const response = await dispatch(claimFlexibleSavingInvestment({ id: selectedInvestment._id, cfdAccountId: currency }));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsButtonLoading(false);

        return;
      }

      await dispatch(getTotalBalance(userId));
      await dispatch(getAccounts(userId));
      dispatch(decrementSavingAccountBalance({ currencyId: savingAccountId, amount: earnings }));
      setStep(2);
      setIsButtonLoading(false);
    }
  }

  const handleLockedConfirm = async (): Promise<void> => {
    if (selectedInvestment !== undefined && selectedInvestment !== null && userId !== undefined) {
      setIsButtonLoading(true);
      const response = await dispatch(redeemSavingInvestment({ savingInvestmentId: selectedInvestment._id, currencyId: currency }));
      const payload = response.payload as PayloadInterface;

      if (payload.isError) {
        handleError(payload, handleOpenSnackbar);
        setIsButtonLoading(false);

        return;
      }

      await dispatch(getTotalBalance(userId))
      await dispatch(getAccounts(userId));
      dispatch(decrementSavingAccountBalance({ currencyId: savingAccountId, amount: String(selectedInvestment.finalEarnings) }))
      setStep(2);
    }
  }

  const viewHistoryTableBtnHandler = (): void => {
    localStorage.setItem(savingsPageStorage, 'History')
    setStep(1);
    setOpen(false);
    setSection('History')
  }

  const savingAccountsBtnHandler = (): void => {
    setOpen(false);
    setStep(1)
  }

  return (
    <footer className={ style.footerWrapper }>
        { step === 1
          ? <>
          <div className={style.footerAgreement}>
            <CheckboxComponent onChange={handleCheckbox} checked={confirmAgreement} />
            <p className={style.footerAgreementText}>{ t('savings_page.subscribe_modal.I_have_read_and_agree') }
              <a href='https://jmpcapitals.io/terms-conditions/' className={style.footerAgreementLink}>
              { t('savings_page.subscribe_modal.this_link_name') }
              </a>
            </p>
          </div>
          <div className={style.footerBtnContainer}>
            <ButtonComponent disabled={!confirmAgreement} loading={isButtonLoading} onClick={() => { (isFlexible ?? false) ? handleFlexibleConfirm() : handleLockedConfirm() }}>
              { t('labels.confirm') }
            </ButtonComponent>
          </div>
        </>
          : <div className={ style.btnsWrapper } >
              <div className={ style.btnContinue }>
                <ButtonComponent variant='outlined' customInlineStyles={{ paddingLeft: '10px', paddingRight: '10px' }} onClick={ savingAccountsBtnHandler }>
                  <span className={ style.btnTxt }>{ t('labels.continue_in_saving_accounts') }</span>
                </ButtonComponent>
              </div>
              <div className={ style.btnView }>
                <ButtonComponent onClick={ viewHistoryTableBtnHandler }>
                  { t('labels.view_savings_history') }
                </ButtonComponent>
              </div>
          </div> }
    </footer>
  )
}
