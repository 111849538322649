import React, { type FC } from 'react';

export const DefaultLogo: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSurface)';

  return (
  <svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.30859 27.1296C4.00859 26.0796 0.808594 22.1796 0.808594 17.5196C0.808594 13.5596 3.12859 10.1396 6.46859 8.55957C4.05859 9.98957 2.43859 12.6396 2.43859 15.6696C2.43859 18.2296 3.59859 20.5196 5.40859 22.0296V17.7196C5.80859 16.1496 6.88859 14.8496 8.30859 14.1696V23.5896V25.6096V27.1296Z" fill="url(#paint0_radial_55944_1370057)"/>
  <mask id="mask0_55944_1370057" maskUnits="userSpaceOnUse" x="0" y="8" width="9" height="20">
  <path d="M8.30859 27.1296C4.00859 26.0796 0.808594 22.1796 0.808594 17.5196C0.808594 13.5596 3.12859 10.1396 6.46859 8.55957C4.05859 9.98957 2.43859 12.6396 2.43859 15.6696C2.43859 18.2296 3.59859 20.5196 5.40859 22.0296V17.7196C5.80859 16.1496 6.88859 14.8496 8.30859 14.1696V23.5896V25.6096V27.1296Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_55944_1370057)">
  <path d="M8.30859 8.55957H0.808594V27.1296H8.30859V8.55957Z" fill="url(#paint1_radial_55944_1370057)"/>
  </g>
  <path d="M12.068 27.3002C11.598 27.3702 11.108 27.4102 10.618 27.4102C10.128 27.4102 9.63797 27.3702 9.16797 27.3002V25.6002V23.7802V13.1602L10.618 11.4902L12.068 13.1602V23.7802V25.6002V27.3002Z" fill="url(#paint2_linear_55944_1370057)"/>
  <mask id="mask1_55944_1370057" maskUnits="userSpaceOnUse" x="9" y="11" width="4" height="17">
  <path d="M12.068 27.3002C11.598 27.3702 11.108 27.4102 10.618 27.4102C10.128 27.4102 9.63797 27.3702 9.16797 27.3002V25.6002V23.7802V13.1602L10.618 11.4902L12.068 13.1602V23.7802V25.6002V27.3002Z" fill="white"/>
  </mask>
  <g mask="url(#mask1_55944_1370057)">
  <path d="M12.068 11.4902H9.16797V27.4002H12.068V11.4902Z" fill="url(#paint3_linear_55944_1370057)"/>
  </g>
  <path d="M14.7697 8.55957C18.1097 10.1396 20.4297 13.5496 20.4297 17.5196C20.4297 22.1796 17.2397 26.0796 12.9297 27.1296V25.5996V23.5796V14.1596C14.3497 14.8396 15.4297 16.1396 15.8297 17.7096V22.0196C17.6397 20.5096 18.7997 18.2196 18.7997 15.6596C18.7997 12.6396 17.1797 9.98957 14.7697 8.55957Z" fill="url(#paint4_radial_55944_1370057)"/>
  <mask id="mask2_55944_1370057" maskUnits="userSpaceOnUse" x="12" y="8" width="9" height="20">
  <path d="M14.7697 8.55957C18.1097 10.1396 20.4297 13.5496 20.4297 17.5196C20.4297 22.1796 17.2397 26.0796 12.9297 27.1296V25.5996V23.5796V14.1596C14.3497 14.8396 15.4297 16.1396 15.8297 17.7096V22.0196C17.6397 20.5096 18.7997 18.2196 18.7997 15.6596C18.7997 12.6396 17.1797 9.98957 14.7697 8.55957Z" fill="white"/>
  </mask>
  <g mask="url(#mask2_55944_1370057)">
  <path d="M20.4297 8.55957H12.9297V27.1296H20.4297V8.55957Z" fill="url(#paint5_radial_55944_1370057)"/>
  </g>
  <path d="M10.6169 8.29004C14.6569 8.29004 17.9369 11.59 17.9369 15.67C17.9369 17.19 17.4769 18.61 16.6869 19.79V18.34C16.7969 17.88 16.8469 17.4 16.8469 16.91C16.8469 13.44 14.0569 10.62 10.6069 10.62C7.15687 10.62 4.36687 13.43 4.36687 16.91C4.36687 17.4 4.42688 17.88 4.52688 18.34V19.79C3.75688 18.61 3.29688 17.2 3.29688 15.67C3.29688 11.6 6.57687 8.29004 10.6169 8.29004Z" fill="url(#paint6_radial_55944_1370057)"/>
  <mask id="mask3_55944_1370057" maskUnits="userSpaceOnUse" x="3" y="8" width="15" height="12">
  <path d="M10.6169 8.29004C14.6569 8.29004 17.9369 11.59 17.9369 15.67C17.9369 17.19 17.4769 18.61 16.6869 19.79V18.34C16.7969 17.88 16.8469 17.4 16.8469 16.91C16.8469 13.44 14.0569 10.62 10.6069 10.62C7.15687 10.62 4.36687 13.43 4.36687 16.91C4.36687 17.4 4.42688 17.88 4.52688 18.34V19.79C3.75688 18.61 3.29688 17.2 3.29688 15.67C3.29688 11.6 6.57687 8.29004 10.6169 8.29004Z" fill="white"/>
  </mask>
  <g mask="url(#mask3_55944_1370057)">
  <path d="M17.9369 8.29004H3.29688V19.79H17.9369V8.29004Z" fill="url(#paint7_radial_55944_1370057)"/>
  </g>
  <path d="M16.9095 18.9995C17.4395 17.9795 17.7195 16.8395 17.7195 15.6795C17.7195 11.7295 14.5395 8.51953 10.6195 8.51953C6.69953 8.51953 3.51953 11.7295 3.51953 15.6795C3.51953 16.8495 3.79953 17.9795 4.32953 18.9995C3.22953 16.1595 4.13953 12.9395 6.54953 11.0995C8.95953 9.25953 12.2795 9.25953 14.6895 11.0995C17.0995 12.9295 18.0095 16.1595 16.9095 18.9995Z" fill="url(#paint8_radial_55944_1370057)"/>
  <mask id="mask4_55944_1370057" maskUnits="userSpaceOnUse" x="3" y="8" width="15" height="11">
  <path d="M16.9095 18.9995C17.4395 17.9795 17.7195 16.8395 17.7195 15.6795C17.7195 11.7295 14.5395 8.51953 10.6195 8.51953C6.69953 8.51953 3.51953 11.7295 3.51953 15.6795C3.51953 16.8495 3.79953 17.9795 4.32953 18.9995C3.22953 16.1595 4.13953 12.9395 6.54953 11.0995C8.95953 9.25953 12.2795 9.25953 14.6895 11.0995C17.0995 12.9295 18.0095 16.1595 16.9095 18.9995Z" fill="white"/>
  </mask>
  <g mask="url(#mask4_55944_1370057)">
  <path d="M18.0005 8.50977H3.23047V18.9898H18.0005V8.50977Z" fill="url(#paint9_radial_55944_1370057)"/>
  </g>
  <path d="M23.418 27.7796C22.658 27.7796 21.918 27.6796 21.188 27.4696C20.458 27.2696 19.868 26.9496 19.418 26.5296L20.508 23.3096C20.738 23.4096 20.978 23.4996 21.248 23.5796C21.518 23.6596 21.778 23.6996 22.038 23.6996C23.118 23.6996 23.658 23.0796 23.658 21.8496V10.5996H28.858V21.5296C28.858 23.5796 28.388 25.1296 27.458 26.1896C26.528 27.2496 25.178 27.7796 23.418 27.7796Z" fill={fillColor}/>
  <path d="M30.5898 27.3896V10.5996H35.6198L39.7798 18.7496L43.8498 10.5996H48.8798V27.3896H43.8498V18.6096L40.7798 24.4696H38.7198L35.6198 18.6096V27.3896H30.5898Z" fill={fillColor}/>
  <path d="M50.6992 27.3901V10.5801H56.3392C59.0792 10.5801 61.1492 11.0301 62.5492 11.9301C63.9492 12.8301 64.6392 14.1601 64.6392 15.9201C64.6392 17.1401 64.3392 18.1801 63.7292 19.0301C63.1192 19.8801 62.2692 20.5301 61.1792 20.9801C60.0792 21.4301 58.7892 21.6501 57.3092 21.6501H56.0492V27.3801H50.6992V27.3901ZM57.0892 18.2601C57.9492 18.2601 58.6092 18.0801 59.0692 17.7201C59.5392 17.3601 59.7692 16.8301 59.7692 16.1101C59.7692 15.4401 59.5392 14.9401 59.0892 14.6101C58.6392 14.2801 57.9792 14.1101 57.1292 14.1101H56.0392V18.2601H57.0892Z" fill={fillColor}/>
  <path d="M71.0275 27.5802C69.6675 27.5802 68.4975 27.3102 67.5175 26.7702C66.5375 26.2302 65.7775 25.5002 65.2375 24.5602C64.7075 23.6202 64.4375 22.5502 64.4375 21.3302C64.4375 20.1302 64.7075 19.0502 65.2575 18.0902C65.8075 17.1302 66.5775 16.3702 67.5675 15.8202C68.5575 15.2702 69.7075 14.9902 71.0175 14.9902C72.5775 14.9902 73.8175 15.3202 74.7175 15.9702L74.1875 19.2902C73.7875 19.1102 73.4075 18.9902 73.0475 18.9102C72.6875 18.8402 72.3575 18.8002 72.0275 18.8002C71.2375 18.8002 70.6075 19.0202 70.1475 19.4702C69.6875 19.9202 69.4575 20.5202 69.4575 21.2902C69.4575 22.0402 69.6875 22.6402 70.1375 23.1102C70.5875 23.5702 71.1875 23.8102 71.9275 23.8102C72.5775 23.8102 73.3275 23.6702 74.2075 23.4002L74.6675 26.8202C74.1375 27.0802 73.5775 27.2802 73.0075 27.4102C72.4475 27.5202 71.7875 27.5802 71.0275 27.5802Z" fill={fillColor}/>
  <path d="M80.9092 27.58C79.7992 27.58 78.7892 27.31 77.8792 26.76C76.9792 26.22 76.2592 25.46 75.7392 24.5C75.2192 23.54 74.9492 22.44 74.9492 21.21C74.9492 19.97 75.2092 18.89 75.7392 17.97C76.2592 17.04 76.9792 16.32 77.8792 15.79C78.7792 15.26 79.7892 15 80.9092 15C81.6992 15 82.4692 15.22 83.2092 15.66C83.9492 16.1 84.5192 16.82 84.9292 17.83V15.17H89.9592V27.39H84.8792V25.12C84.4892 25.92 83.9792 26.53 83.3292 26.95C82.6792 27.37 81.8692 27.58 80.9092 27.58ZM82.4792 23.73C83.1392 23.73 83.7092 23.49 84.1692 23.01C84.6392 22.53 84.8692 21.94 84.8692 21.24C84.8692 20.54 84.6392 19.97 84.1692 19.52C83.6992 19.07 83.1392 18.85 82.4792 18.85C81.7892 18.85 81.2092 19.09 80.7392 19.56C80.2692 20.03 80.0392 20.61 80.0392 21.29C80.0392 21.97 80.2692 22.55 80.7392 23.02C81.1992 23.49 81.7792 23.73 82.4792 23.73Z" fill={fillColor}/>
  <path d="M96.6373 31.24L91.5273 32V15.17H96.5573V17.83C96.9773 16.82 97.5573 16.1 98.2873 15.66C99.0173 15.22 99.7873 15 100.577 15C101.707 15 102.717 15.26 103.617 15.79C104.517 16.32 105.227 17.05 105.747 17.97C106.267 18.9 106.537 19.98 106.537 21.21C106.537 22.45 106.277 23.54 105.747 24.5C105.227 25.46 104.517 26.21 103.617 26.76C102.717 27.31 101.707 27.58 100.577 27.58C99.6073 27.58 98.7973 27.37 98.1573 26.95C97.5073 26.53 97.0073 25.92 96.6373 25.12V31.24ZM99.0073 23.73C99.6973 23.73 100.277 23.49 100.747 23.02C101.217 22.55 101.447 21.97 101.447 21.29C101.447 20.61 101.217 20.03 100.747 19.56C100.277 19.09 99.6973 18.85 99.0073 18.85C98.3473 18.85 97.7773 19.07 97.3173 19.52C96.8473 19.97 96.6173 20.54 96.6173 21.24C96.6173 21.94 96.8473 22.53 97.3173 23.01C97.7773 23.49 98.3473 23.73 99.0073 23.73Z" fill={fillColor}/>
  <path d="M114.638 23.3402C114.298 23.5402 113.968 23.6302 113.648 23.6302C113.338 23.6302 113.078 23.5302 112.858 23.3202C112.638 23.1202 112.528 22.8302 112.528 22.4502V15.1602H107.418V23.1602C107.418 23.9902 107.568 24.7402 107.878 25.4002C108.188 26.0702 108.668 26.6002 109.338 26.9902C110.008 27.3802 110.878 27.5802 111.968 27.5802C112.438 27.5802 112.948 27.5102 113.518 27.3602C114.078 27.2102 114.538 27.0002 114.898 26.7302L114.638 23.3402Z" fill={fillColor}/>
  <path d="M120.206 27.5798C119.056 27.5798 118.126 27.3798 117.406 26.9898C116.686 26.5998 116.166 26.0698 115.846 25.3998C115.526 24.7298 115.366 23.9798 115.366 23.1598V18.1798H113.816V15.1498H115.726L117.976 11.2998H120.466V15.1498H123.276V18.1798H120.466V22.4498C120.466 22.8198 120.586 23.1098 120.826 23.3198C121.066 23.5198 121.386 23.6298 121.766 23.6298C122.156 23.6298 122.536 23.5298 122.926 23.3398L123.286 26.7298C122.896 27.0098 122.406 27.2198 121.806 27.3598C121.226 27.5098 120.686 27.5798 120.206 27.5798Z" fill={fillColor}/>
  <path d="M129.308 27.58C128.198 27.58 127.188 27.31 126.278 26.76C125.378 26.22 124.658 25.46 124.138 24.5C123.618 23.54 123.348 22.44 123.348 21.21C123.348 19.97 123.608 18.89 124.138 17.97C124.658 17.04 125.378 16.32 126.278 15.79C127.178 15.26 128.188 15 129.308 15C130.098 15 130.868 15.22 131.608 15.66C132.348 16.1 132.918 16.82 133.328 17.83V15.17H138.358V27.39H133.278V25.12C132.888 25.92 132.378 26.53 131.728 26.95C131.078 27.37 130.278 27.58 129.308 27.58ZM130.878 23.73C131.538 23.73 132.108 23.49 132.568 23.01C133.038 22.53 133.268 21.94 133.268 21.24C133.268 20.54 133.038 19.97 132.568 19.52C132.098 19.07 131.538 18.85 130.878 18.85C130.188 18.85 129.608 19.09 129.138 19.56C128.668 20.03 128.438 20.61 128.438 21.29C128.438 21.97 128.668 22.55 129.138 23.02C129.608 23.49 130.188 23.73 130.878 23.73Z" fill={fillColor}/>
  <path d="M144.699 27.5796C143.549 27.5796 142.619 27.3796 141.899 26.9896C141.179 26.5996 140.659 26.0696 140.339 25.3996C140.019 24.7296 139.859 23.9796 139.859 23.1596V10.8196L144.969 10.0596V22.4496C144.969 22.8196 145.089 23.1096 145.329 23.3196C145.569 23.5196 145.889 23.6296 146.269 23.6296C146.659 23.6296 147.039 23.5296 147.429 23.3396L147.789 26.7296C147.399 27.0096 146.909 27.2196 146.309 27.3596C145.729 27.5096 145.189 27.5796 144.699 27.5796Z" fill={fillColor}/>
  <path d="M109.25 13.5896C107.92 13.2696 106.93 12.0596 106.93 10.6196C106.93 9.38961 107.65 8.33961 108.68 7.84961C107.93 8.28961 107.43 9.10961 107.43 10.0496C107.43 10.8396 107.79 11.5496 108.35 12.0196V10.6896C108.47 10.1996 108.81 9.79961 109.25 9.58961V12.4996V13.1296V13.5896V13.5896Z" fill="url(#paint10_radial_55944_1370057)"/>
  <mask id="mask5_55944_1370057" maskUnits="userSpaceOnUse" x="106" y="7" width="4" height="7">
  <path d="M109.25 13.5896C107.92 13.2696 106.93 12.0596 106.93 10.6196C106.93 9.38961 107.65 8.33961 108.68 7.84961C107.93 8.28961 107.43 9.10961 107.43 10.0496C107.43 10.8396 107.79 11.5496 108.35 12.0196V10.6896C108.47 10.1996 108.81 9.79961 109.25 9.58961V12.4996V13.1296V13.5896V13.5896Z" fill="white"/>
  </mask>
  <g mask="url(#mask5_55944_1370057)">
  <path d="M109.25 7.84961H106.93V13.5896H109.25V7.84961Z" fill="url(#paint11_radial_55944_1370057)"/>
  </g>
  <path d="M110.408 13.64C110.258 13.66 110.108 13.67 109.958 13.67C109.808 13.67 109.658 13.66 109.508 13.64V13.12V12.56V9.27L109.958 8.75L110.408 9.27V12.55V13.11V13.64Z" fill="url(#paint12_linear_55944_1370057)"/>
  <mask id="mask6_55944_1370057" maskUnits="userSpaceOnUse" x="109" y="8" width="2" height="6">
  <path d="M110.408 13.64C110.258 13.66 110.108 13.67 109.958 13.67C109.808 13.67 109.658 13.66 109.508 13.64V13.12V12.56V9.27L109.958 8.75L110.408 9.27V12.55V13.11V13.64Z" fill="white"/>
  </mask>
  <g mask="url(#mask6_55944_1370057)">
  <path d="M110.408 8.75H109.508V13.67H110.408V8.75Z" fill="url(#paint13_linear_55944_1370057)"/>
  </g>
  <path d="M111.25 7.84961C112.28 8.33961 113 9.38961 113 10.6196C113 12.0596 112.01 13.2696 110.68 13.5896V13.1196V12.4896V9.57961C111.12 9.78961 111.45 10.1896 111.58 10.6796V12.0096C112.14 11.5396 112.5 10.8396 112.5 10.0396C112.49 9.10961 111.99 8.28961 111.25 7.84961Z" fill="url(#paint14_radial_55944_1370057)"/>
  <mask id="mask7_55944_1370057" maskUnits="userSpaceOnUse" x="110" y="7" width="3" height="7">
  <path d="M111.25 7.84961C112.28 8.33961 113 9.38961 113 10.6196C113 12.0596 112.01 13.2696 110.68 13.5896V13.1196V12.4896V9.57961C111.12 9.78961 111.45 10.1896 111.58 10.6796V12.0096C112.14 11.5396 112.5 10.8396 112.5 10.0396C112.49 9.10961 111.99 8.28961 111.25 7.84961Z" fill="white"/>
  </mask>
  <g mask="url(#mask7_55944_1370057)">
  <path d="M113 7.84961H110.68V13.5896H113V7.84961Z" fill="url(#paint15_radial_55944_1370057)"/>
  </g>
  <path d="M109.959 7.75977C111.209 7.75977 112.219 8.77977 112.219 10.0398C112.219 10.5098 112.079 10.9498 111.839 11.3098V10.8598C111.869 10.7198 111.889 10.5698 111.889 10.4198C111.889 9.34977 111.029 8.47976 109.959 8.47976C108.889 8.47976 108.029 9.34977 108.029 10.4198C108.029 10.5698 108.049 10.7198 108.079 10.8598V11.3098C107.839 10.9498 107.699 10.5098 107.699 10.0398C107.699 8.78977 108.709 7.75977 109.959 7.75977Z" fill="url(#paint16_radial_55944_1370057)"/>
  <mask id="mask8_55944_1370057" maskUnits="userSpaceOnUse" x="107" y="7" width="6" height="5">
  <path d="M109.959 7.75977C111.209 7.75977 112.219 8.77977 112.219 10.0398C112.219 10.5098 112.079 10.9498 111.839 11.3098V10.8598C111.869 10.7198 111.889 10.5698 111.889 10.4198C111.889 9.34977 111.029 8.47976 109.959 8.47976C108.889 8.47976 108.029 9.34977 108.029 10.4198C108.029 10.5698 108.049 10.7198 108.079 10.8598V11.3098C107.839 10.9498 107.699 10.5098 107.699 10.0398C107.699 8.78977 108.709 7.75977 109.959 7.75977Z" fill="white"/>
  </mask>
  <g mask="url(#mask8_55944_1370057)">
  <path d="M112.229 7.75977H107.699V11.3098H112.229V7.75977Z" fill="url(#paint17_radial_55944_1370057)"/>
  </g>
  <path d="M111.908 11.0701C112.068 10.7501 112.158 10.4001 112.158 10.0401C112.158 8.82008 111.178 7.83008 109.958 7.83008C108.738 7.83008 107.758 8.82008 107.758 10.0401C107.758 10.4001 107.848 10.7501 108.008 11.0701C107.668 10.1901 107.948 9.20008 108.698 8.63008C109.438 8.06008 110.468 8.06008 111.218 8.63008C111.968 9.20008 112.248 10.2001 111.908 11.0701Z" fill="url(#paint18_radial_55944_1370057)"/>
  <mask id="mask9_55944_1370057" maskUnits="userSpaceOnUse" x="107" y="7" width="6" height="5">
  <path d="M111.908 11.0701C112.068 10.7501 112.158 10.4001 112.158 10.0401C112.158 8.82008 111.178 7.83008 109.958 7.83008C108.738 7.83008 107.758 8.82008 107.758 10.0401C107.758 10.4001 107.848 10.7501 108.008 11.0701C107.668 10.1901 107.948 9.20008 108.698 8.63008C109.438 8.06008 110.468 8.06008 111.218 8.63008C111.968 9.20008 112.248 10.2001 111.908 11.0701Z" fill="white"/>
  </mask>
  <g mask="url(#mask9_55944_1370057)">
  <path d="M112.25 7.83008H107.68V11.0701H112.25V7.83008Z" fill="url(#paint19_radial_55944_1370057)"/>
  </g>
  <path d="M153.066 27.2698C152.276 27.2698 151.476 27.1898 150.666 27.0298C149.856 26.8698 149.126 26.6498 148.476 26.3698L149.236 23.0198C149.636 23.2298 150.196 23.4098 150.896 23.5598C151.596 23.7098 152.216 23.7898 152.766 23.7898C153.646 23.7898 154.086 23.5498 154.086 23.0698C154.086 22.8398 153.946 22.6698 153.676 22.5598C153.406 22.4498 153.056 22.3598 152.626 22.2798C152.196 22.1998 151.736 22.0998 151.246 21.9598C150.756 21.8298 150.296 21.6198 149.866 21.3498C149.436 21.0798 149.086 20.6798 148.816 20.1698C148.546 19.6598 148.406 18.9798 148.406 18.1498C148.406 16.7498 148.956 15.6398 150.056 14.7998C151.156 13.9698 152.656 13.5498 154.576 13.5498C155.316 13.5498 156.006 13.5998 156.646 13.7098C157.286 13.8198 157.896 14.0398 158.476 14.3698L157.926 17.6098C156.996 17.2198 156.016 17.0298 155.006 17.0298C154.356 17.0298 153.886 17.0798 153.596 17.1898C153.306 17.2998 153.166 17.4698 153.166 17.7198C153.166 17.9098 153.306 18.0598 153.586 18.1698C153.866 18.2798 154.236 18.3798 154.676 18.4798C155.126 18.5798 155.596 18.7098 156.086 18.8798C156.576 19.0498 157.046 19.2798 157.496 19.5798C157.946 19.8798 158.306 20.2798 158.586 20.7898C158.866 21.2998 159.006 21.9398 159.006 22.7398C159.006 24.1598 158.486 25.2598 157.456 26.0598C156.406 26.8698 154.946 27.2698 153.066 27.2698Z" fill={fillColor}/>
  <defs>
  <radialGradient id="paint0_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.56004 17.8433) scale(7.03095 7.08797)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint1_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.56004 17.8433) scale(7.03095 7.08797)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <linearGradient id="paint2_linear_55944_1370057" x1="-2.92613" y1="19.4485" x2="18.8494" y2="19.4485" gradientUnits="userSpaceOnUse">
  <stop stopColor="#23B9A3"/>
  <stop offset="0.5177" stopColor="#86FCEB"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </linearGradient>
  <linearGradient id="paint3_linear_55944_1370057" x1="10.6254" y1="12.3339" x2="10.6034" y2="39.4017" gradientUnits="userSpaceOnUse">
  <stop offset="0.0558" stopColor="#23B9A3"/>
  <stop offset="0.2841" stopColor="#86FCEB"/>
  <stop offset="0.5569" stopColor="#1DB59F"/>
  </linearGradient>
  <radialGradient id="paint4_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.6809 17.8433) scale(7.03095 7.08797)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint5_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.6809 17.8433) scale(7.03095 7.08797)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint6_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.6182 14.0427) scale(6.56042 6.61362)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint7_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.6182 14.0427) scale(6.56042 6.61362)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint8_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.6209 13.7545) scale(6.22312 6.27359)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.6769" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint9_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.6218 13.7547) scale(6.38743 6.43923)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.6769" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint10_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.086 10.7162) scale(2.17379 2.19142)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint11_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.086 10.7162) scale(2.17379 2.19142)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <linearGradient id="paint12_linear_55944_1370057" x1="105.773" y1="11.2125" x2="112.505" y2="11.2125" gradientUnits="userSpaceOnUse">
  <stop stopColor="#23B9A3"/>
  <stop offset="0.5177" stopColor="#86FCEB"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </linearGradient>
  <linearGradient id="paint13_linear_55944_1370057" x1="109.962" y1="9.0129" x2="109.956" y2="17.3814" gradientUnits="userSpaceOnUse">
  <stop offset="0.0558" stopColor="#23B9A3"/>
  <stop offset="0.2841" stopColor="#86FCEB"/>
  <stop offset="0.5569" stopColor="#1DB59F"/>
  </linearGradient>
  <radialGradient id="paint14_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.833 10.7162) scale(2.17379 2.19142)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint15_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.833 10.7162) scale(2.17379 2.19142)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint16_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.959 9.54122) scale(2.02826 2.04471)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint17_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.959 9.54122) scale(2.02827 2.04471)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.2821" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint18_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.958 9.45252) scale(1.92399 1.93959)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.6769" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  <radialGradient id="paint19_radial_55944_1370057" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.96 9.45252) scale(1.97473 1.99074)">
  <stop stopColor="#86FCEB"/>
  <stop offset="0.6769" stopColor="#27DEC8"/>
  <stop offset="1" stopColor="#1DB59F"/>
  </radialGradient>
  </defs>
  </svg>
  )
}
