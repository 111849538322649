import React, { type FC, useState, useMemo } from 'react';

import { useAppSelector } from '../../../../hooks/redux';
import LogoIcon from '../../../icons/LogoIcon.svg';
import { AddFundsIcon } from '../../../icons/AddFundsIcon';
import { FabComponent, TransactionModalContext } from '../../../../components';
import { DepositComponent } from '../../../../pages/Accounts/DepositSection/DepositComponent';

import style from '../HeaderLiveChatComponent.module.css';

export const MobileHeaderLiveChatComponent: FC = () => {
  const { firstName } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts])

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={selectedAccount} />
        }
    />
    )
  }, [openDeposit, selectedAccount]);

  const depositBtn = (): void => {
    setOpenDeposit(true);
  }

  return (
    <>
    <div className={ style.headerLiveMobileChat }>
        <div className={ style.headerLiveMobileLogo }>
          <img src={LogoIcon} alt="LogoIcon" />
        </div>

        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small'
            onClick={ depositBtn }
            >
              <AddFundsIcon color='var(--CFD-theme-System-Primary)'/>
              <span>Deposit</span>
            </FabComponent>
          </div>
          {
            (firstName !== undefined && firstName.length > 0) && (
              <div className={ style.headerLiveMobileUserIcon }>
                { firstName[0] }
              </div>
            )
          }
        </div>
      </div>
      { TransactionModal }
    </>
  )
}
